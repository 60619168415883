<template>
	<v-flex v-if="simpleButtonDisplay && !listTileDisplay" shrink>
		<w-btn v-if="canSign" flat icon="mdi-draw" mini @click="startSignatureProcedure()">
			{{ $t('signature.documents.sign_document') }}
		</w-btn>
		<FilePreview v-if="displayDocumentToSign" v-model="documentToSign" @close="displayDocumentToSign = false" />
		<w-btn v-if="canSeeSignatories" flat icon="fact_check" mini @click="showSignatories()">
			{{ $t('signature.documents.show_signatories_list') }}
		</w-btn>
		<SignatoriesList v-if="displaySignatoriesList" v-model="document" @close="displaySignatoriesList = false" />
	</v-flex>
	<div v-else-if="listTileDisplay">
		<v-list-tile v-if="canSign" avatar @click="startSignatureProcedure()">
			<v-list-tile-avatar>
				<v-icon color="primary">mdi-draw</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'signature.documents.sign_document'" />
			</v-list-tile-content>
		</v-list-tile>
		<FilePreview v-if="displayDocumentToSign" v-model="documentToSign" @close="displayDocumentToSign = false" />
		<v-list-tile v-if="canSeeSignatories" avatar @click="showSignatories()">
			<v-list-tile-avatar>
				<v-icon color="primary">fact_check</v-icon>
			</v-list-tile-avatar>
			<v-list-tile-content>
				<v-list-tile-title v-t="'signature.documents.show_signatories_list'" />
			</v-list-tile-content>
		</v-list-tile>
		<SignatoriesList v-if="displaySignatoriesList" v-model="document" @close="displaySignatoriesList = false" />
	</div>
</template>

<script>
import SignatureModuleGuard from '@/mixins/ModulesGuards/Signature/SignatureModuleGuard'
import * as Files from '@/helpers/files'

export default {
	name: 'SignatureButtons',
	components: {
		SignatoriesList: () => ({
			component: import('@/components/Documents/Signature/SignatoriesList')
		}),
		FilePreview: () => ({
			component: import('@/components/Documents/Signature/FilePreview')
		})
	},
	mixins: [SignatureModuleGuard],
	props: {
		simpleButtonDisplay: {
			type: Boolean,
			required: false,
			default: true
		},
		listTileDisplay: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: [Object, Array],
			required: true
		}
	},
	data: function () {
		return {
			displayDocumentToSign: false,
			displaySignatoriesList: false,
			documentToSign: null,
			loading: false,
			supportedFileTypes: [Files.mimetypes.pdf, Files.mimetypes.pdf_a, Files.mimetypes.jpeg, Files.mimetypes.jpg, Files.mimetypes.png],
			OAUTH_PROVIDER_JESIGNEXPERT: 'jesignexpert',
			QUEUED_HAS_SIGNATURES_REQUESTS: {}
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		nodes: function () {
			return Array.isArray(this.model) ? this.model : [this.model]
		},
		document: function () {
			return this.nodes.every(node => !node.is_folder) && this.nodes.length == 1 ? this.nodes[0] : null
		},
		canSign: function () {
			return (
				this.document &&
				!this.document.has_signatories &&
				this.supportedFileTypes.includes(this.document.type) &&
				!this.document.is_readonly &&
				!this.document.deleted_at &&
				!this.loading
			)
		},
		canSeeSignatories: function () {
			return this.document && this.document.has_signatories && !this.document.deleted_at && !this.loading
		}
	},
	methods: {
		startSignatureProcedure: function () {
			// Authentification
			this.service.getAuthenticationUserStatus(this.OAUTH_PROVIDER_JESIGNEXPERT, this.userId).then(authentication => {
				let result = Promise.resolve()
				if (authentication.status !== 'completed') {
					result = this.authenticateUser()
				}
				// Ouverture du document dans la liseuse pour le signer
				return result
					.then(() => {
						this.documentToSign = this.document
						this.displayDocumentToSign = true
					})
					.catch(() => {
						this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('signature.documents.authentication_required'))
					})
			})
		},
		showSignatories: function () {
			this.displaySignatoriesList = true
		}
	}
}
</script>
